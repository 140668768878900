<template>
  <div v-if="noSelectedLanguage">
    <p>Please select language to manage tags</p>
  </div>
  <div v-else>
    <table class="table">
      <thead class="table-light">
        <tr>
          <th class="col-1">Tag</th>
          <th v-if="showDefaultLanguage" class="col-2">
            Default language text
          </th>
          <th :class="showDefaultLanguage ? 'col-3' : 'col-2and3'">Text</th>
          <th class="col-4"></th>
        </tr>
        <tr>
          <th class="col-1">
            <input class="form-control" v-model="tag" @change="update" />
          </th>
          <th v-if="showDefaultLanguage" class="col-2"></th>
          <th :class="showDefaultLanguage ? 'col-3' : 'col-2and3'">
            <input class="form-control" v-model="text" @change="update" />
          </th>
          <th class="col-4">
            <button
              class="form-control btn-green"
              :class="canAddTag() ? 'btn-green' : 'btn-disabled'"
              @click="addTag"
              :disabled="canAddTag() === false"
            >
              Add tag
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item of getTags.filter(
            (v) =>
              (v.tag.toLowerCase().includes(tag.toLowerCase()) ||
                tag.length < 2) &&
              ((getTranslations[v.tag] ? getTranslations[v.tag] : '')
                .toLowerCase()
                .includes(text.toLowerCase()) ||
                text.length < 2)
          )"
          :key="item.id"
        >
          <td class="col-1">
            <input
              type="text"
              class="form-control"
              v-model="item.tag"
              @change="updateTag(item)"
            />
          </td>
          <td v-if="showDefaultLanguage" class="col-2">
            <textarea
              type="text"
              class="form-control"
              v-model="getDefaultTranslations[item.tag]"
              readonly
            />
          </td>
          <td :class="showDefaultLanguage ? 'col-3' : 'col-2and3'">
            <textarea
              type="text"
              class="form-control"
              v-model="getTranslations[item.tag]"
              @change="
                updateTranslation({ tag: item.tag, text: $event.target.value })
              "
            />
          </td>
          <td class="col-4">
            <button class="btn btn-danger" @click="openTagDeleteModal(item)">
              Delete tag
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <delete-tag-modal
    v-if="showTagDeleteModal"
    :tagToDelete="tagToDelete"
    v-on:hideDeleteModal="closeTagDeleteModal"
    v-on:deleteTag="deleteTag"
  />
</template>

<script>
import { useStore } from "vuex";
import DeleteTagModal from "@/components/modals/DeleteTagModal";

export default {
  name: "Tags",
  components: {
    DeleteTagModal,
  },
  async setup() {
    const store = useStore();
    await store.dispatch("translations/loadTags");
    let languages = store.getters["translations/getLanguages"];
    let user = store.getters["users/getUser"];
    let userLanguage = languages.find(
      (v) => v.languageCode === user.languageCode
    );

    if (!userLanguage) {
      return {
        noSelectedLanguage: true,
      };
    }

    if (userLanguage.isDefault === false) {
      await store.dispatch("translations/loadDefaultTranslations");
    }
    let model = {
      tag: "",
      text: "",
      showTagDeleteModal: false,
      tagToDelete: "",
      showDefaultLanguage: userLanguage.isDefault === false,
      noSelectedLanguage: false,
    };
    return model;
  },
  computed: {
    getTags() {
      return this.$store.getters["translations/getTags"];
    },
    getTranslations() {
      return this.$store.getters["translations/getTranslations"];
    },
    getDefaultTranslations() {
      return this.$store.getters["translations/getDefaultTranslations"];
    },
  },
  methods: {
    async addTag() {
      await this.$store.dispatch("translations/addTag", {
        tag: this.tag,
      });
      await await this.$store.dispatch("translations/updateTranslation", {
        tag: this.tag,
        text: this.text,
      });
      this.tag = "";
      this.text = "";
      this.$forceUpdate();
    },
    async updateTag(model) {
      await this.$store.dispatch("translations/updateTag", model);
    },
    async updateTranslation(model) {
      await this.$store.dispatch("translations/updateTranslation", model);
    },
    update() {
      this.$forceUpdate();
    },
    canAddTag() {
      let tags = this.$store.getters["translations/getTags"];
      if (
        this.tag.length < 2 ||
        tags.some((v) => v.tag === this.tag) ||
        this.text.length < 2
      ) {
        return false;
      }
      return true;
    },
    openTagDeleteModal(model) {
      this.tagToDelete = model.tag;
      this.tagToDeleteId = model.id;
      this.showTagDeleteModal = true;
      this.$forceUpdate();
    },
    closeTagDeleteModal() {
      this.tagToDelete = "";
      this.tagToDeleteId = undefined;
      this.showTagDeleteModal = false;
      this.$forceUpdate();
    },
    async deleteTag() {
      await await this.$store.dispatch(
        "translations/deleteTag",
        this.tagToDeleteId
      );
      this.tagToDelete = "";
      this.tagToDeleteId = undefined;
      this.showTagDeleteModal = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-green {
  background-color: rgba(0, 128, 0, 0.7);
  color: white;
}
.btn-disabled {
  background-color: lightgray;
  color: white;
}
textarea {
  resize: none;
  height: 200px;
}
.col-1 {
  width: 20%;
}
.col-2 {
  width: 36%;
}
.col-3 {
  width: 36%;
}
.col-4 {
  width: 8%;
}
.col-2and3 {
  width: 72%;
}
</style>
