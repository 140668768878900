<template>
  <div class="modal" id="myModal" style="display: block">
    <div class="modal-dialog center">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete tag</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('hideDeleteModal')"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Please print the tag name "{{ tagToDelete }}" to confirm action:
          </p>
          <input
            type="text"
            class="form-control"
            v-model="tag"
            @change="update()"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('hideDeleteModal')"
          >
            No
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!tagDeleteConfirmed()"
            @click="$emit('deleteTag')"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tagToDelete: String,
  },
  name: "DeleteTagModal",
  emits: ["hideDeleteModal", "deleteTag"],
  setup() {
    return {
      tag: "",
    };
  },
  methods: {
    tagDeleteConfirmed() {
      return this.tagToDelete === this.tag;
    },
    update() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
