<template>
  <div class="container-fluid">
    <div class="text-center pb-3">
      <h2>Translations</h2>
    </div>
    <div class="mb-4 row">
      <select class="market-select col-2" v-model="tab" @change="update">
        <option value="languages">Languages</option>
        <option value="tags">Tags</option>
      </select>
      <label class="col-2">Language: {{ getSelectedLanguage }}</label>
      <button @click="exportTranslations" class="col-2 btn btn-success">
        Export translations
      </button>
      <label class="col-2" style="text-align: right"
        >Choose file to import from:</label
      >
      <input class="col-2" type="file" id="import" accept=".json" />
      <button @click="importTranslations" class="col-2 btn btn-danger">
        Import translations
      </button>
    </div>
    <div v-if="tab === 'languages'">
      <Languages />
    </div>
    <div v-if="tab === 'tags'">
      <Tags />
    </div>
  </div>
</template>

<script>
import Languages from "../components/translations/Languages.vue";
import Tags from "../components/translations/Tags.vue";
import { useStore } from "vuex";
import { saveAs } from "file-saver";
export default {
  name: "Translations",
  components: {
    Languages,
    Tags,
  },
  async setup() {
    const store = useStore();
    await store.dispatch("users/loadUser");
    await store.dispatch("translations/loadTranslations");
    return {
      tab: "languages",
    };
  },
  methods: {
    update() {
      this.$forceUpdate();
    },
    exportTranslations() {
      let translations = this.$store.getters["translations/getTranslations"];
      let json = JSON.stringify(translations);
      let blob = new Blob([json], { type: "application/json" });
      saveAs(blob, "translations.json");
    },
    importTranslations() {
      let files = document.getElementById("import").files;
      if (files.length <= 0) {
        return;
      }
      let fr = new FileReader();
      fr.onload = async (e) => {
        try {
          let result = JSON.parse(e.target.result);
          await this.$store.dispatch("translations/importTranslations", result);
        } catch (err) {
          console.error(err);
        }
        document.getElementById("import").value = "";
      };
      fr.readAsText(files[0]);
    },
  },
  computed: {
    getSelectedLanguage() {
      return this.$store.getters["users/getUser"].languageCode;
    },
  },
};
</script>

<style lang="scss" scoped></style>
