<template>
  <table class="table">
    <thead class="table-light">
      <tr>
        <th>Name</th>
        <th>Code</th>
        <th class="t-small">Is default</th>
        <th class="t-small">Is active</th>
        <th class="t-small">Is template</th>
        <th></th>
      </tr>
      <tr>
        <th>
          <input class="form-control" v-model="name" @change="update" />
        </th>
        <th>
          <input class="form-control" v-model="languageCode" @change="update" />
        </th>
        <th class="t-small"></th>
        <th class="t-small"></th>
        <th class="t-small"></th>
        <th>
          <button
            class="form-control btn-green"
            :class="canAddLanguage() ? 'btn-green' : 'btn-disabled'"
            @click="addLanguage"
            :disabled="canAddLanguage() === false"
          >
            Add language
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="language of getLanguages.filter(
          (v) =>
            (v.name.toLowerCase().includes(name.toLowerCase()) ||
              name === '') &&
            (v.languageCode
              .toLowerCase()
              .includes(languageCode.toLowerCase()) ||
              languageCode === '')
        )"
        :key="language.id"
      >
        <td>
          <input
            type="text"
            class="form-control"
            v-model="language.name"
            @change="updateLanguage(language)"
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control"
            v-model="language.languageCode"
            @change="updateLanguage(language)"
          />
        </td>
        <td class="t-small">
          <input
            type="checkbox"
            class="form-check-input checkbox"
            v-model="language.isDefault"
            :disabled="language.isDefault"
            @change="updateLanguage(language)"
          />
        </td>
        <td class="t-small">
          <input
            type="checkbox"
            class="form-check-input checkbox"
            v-model="language.isActive"
            :disabled="language.isDefault || language.isTemplate"
            @change="updateLanguage(language)"
          />
        </td>
        <td class="t-small">
          <input
            type="checkbox"
            class="form-check-input checkbox"
            v-model="language.isTemplate"
            :disabled="language.isDefault"
            @change="updateLanguage(language)"
          />
        </td>
        <td>
          <button
            class="form-control"
            :class="
              language.languageCode === getSelectedLanguage
                ? 'btn-disabled'
                : ''
            "
            :disabled="language.languageCode === getSelectedLanguage"
            @click="selectLanguage(language.languageCode)"
          >
            {{
              language.languageCode === getSelectedLanguage
                ? "Selected language"
                : "Select language"
            }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Languages",
  async setup() {
    const store = useStore();
    await store.dispatch("translations/loadLanguages");
    let model = {
      name: "",
      languageCode: "",
    };
    return model;
  },
  computed: {
    getLanguages() {
      return this.$store.getters["translations/getLanguages"];
    },
    getSelectedLanguage() {
      return this.$store.getters["users/getUser"].languageCode;
    },
  },
  methods: {
    async addLanguage() {
      await this.$store.dispatch("translations/addLanguage", {
        languageCode: this.languageCode,
        name: this.name,
      });
      this.languageCode = "";
      this.name = "";
      this.$forceUpdate();
    },
    async selectLanguage(languageCode) {
      await this.$store.dispatch("users/selectLanguage", languageCode);
    },
    async updateLanguage(model) {
      await this.$store.dispatch("translations/updateLanguage", model);
    },
    update() {
      this.$forceUpdate();
    },
    canAddLanguage() {
      let languages = this.$store.getters["translations/getLanguages"];
      if (this.name.length < 2 || languages.some((v) => v.name === this.name)) {
        return false;
      }
      if (
        this.languageCode.length < 2 ||
        languages.some((v) => v.languageCode === this.languageCode)
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-green {
  background-color: rgba(0, 128, 0, 0.7);
  color: white;
}
.btn-disabled {
  background-color: lightgray;
  color: white;
}
th {
  width: 23%;
}
td {
  width: 23%;
}
.t-small {
  width: 10%;
}
</style>
